import React from 'react'
import "@aws-amplify/ui-react/styles.css";
import "react-datepicker/dist/react-datepicker.css";
import { Flex, Divider, Text, Heading, View} from '@aws-amplify/ui-react';

export default function Vehicleinsurancedata({vehicle}) {
    function formatDate(UNIX_timestamp){
      var a = new Date(UNIX_timestamp * 1000);
      var months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
      var year = a.getFullYear();
      var month = months[a.getMonth()];
      var date = a.getDate();
      var hour = a.getHours();
      //var min = a.getMinutes();
      //var sec = a.getSeconds();
      var days = ['Sunday','Monday','Tuesday','Wednesday','Thursday','Friday','Saturday']
      var day = days[a.getDay()];
      if(hour > 12)
      {
        var thour = hour - 12
        var pmam = ' PM';
      } else {
        if(hour < 12)
        {
          if(hour < 1)
          {
            var thour = '12';
            var pmam = ' AM';
          } else {
            var thour = hour;
            var pmam = ' AM';
          }
        } else {
          var thour = '12';
          var pmam = ' PM';
        }
      }
      var time = day + ', ' + month + ' ' + date + ', ' + year + ' - ' + thour + pmam;
      return time;
    }
    return (
      <Flex
        direction="column"
      >
        <View style={{padding:"1rem"}}>
          <Divider />
          {
              vehicle ?
                vehicle.rows ?
                  vehicle.rows[0] ?  
                    "car_insurance_data" in vehicle.rows[0] === false ? 
                            <Flex direction="column">
                                <Heading level={2}>No Insurance Data</Heading>
                                <Text>If this vehicle is new, it may take up to a week for new data to populate.</Text>
                                <Text>If you aren't getting your insurance through <a href='https://agorisk.com/'>Ago</a>, you could be saving a bundle.</Text>
                            </Flex>
                          :
                          vehicle.rows[0]["car_insurance_data"].length < 1 ?
                            <Flex direction="column">
                                <Heading level={2}>No Insurance Data</Heading>
                                <Text>If this vehicle is new, it may take up to a week for new data to populate.</Text>
                                <Text>If you aren't getting your insurance through <a href='https://agorisk.com/'>Ago</a>, you could be saving a bundle.</Text>
                            </Flex>
                          :
                          "TotMls" in vehicle.rows[0]["car_insurance_data"] === false ? //just a general check... since the line above doesn't seem to work
                          <Flex direction="column">
                              <Heading level={2}>No Insurance Data</Heading>
                              <Text>If this vehicle is new, it may take up to a week for new data to populate.</Text>
                              <Text>If you aren't getting your insurance through <a href='https://agorisk.com/'>Ago</a>, you could be saving a bundle.</Text>
                          </Flex>
                          :
                          <Flex direction="column">
                            <Heading level={2}>Insurance Data (7 days)</Heading>
                            <Flex wrap="wrap" gap="2rem" justifyContent="center">
                              <Flex direction="column">
                                <Divider />
                                <table><tbody>
                                  {"period_start" in vehicle.rows[0] ? 
                                  <tr>
                                    <th style={{paddingRight:'10px'}}>Period Start:</th>
                                    <td>{formatDate(vehicle.rows[0]["period_start"])}</td>
                                  </tr>
                                  : undefined}
                                  {"period_end" in vehicle.rows[0] ? 
                                  <tr>
                                    <th style={{paddingRight:'10px'}}>Period End:</th>
                                    <td>{formatDate(vehicle.rows[0]["period_end"])}</td>
                                  </tr>
                                  : undefined}
                                  {"TotMls" in vehicle.rows[0]['car_insurance_data'] ? 
                                  <tr>
                                    <th style={{paddingRight:'10px'}}>Total Miles:</th>
                                    <td>{Math.round(vehicle.rows[0]['car_insurance_data']["TotMls"],0)}</td>
                                  </tr>
                                  : undefined}
                                  {"ScaledRiskBucket" in vehicle.rows[0]["car_insurance_data"] ? 
                                    <tr>
                                      <th style={{paddingRight:'10px'}}>Safety Bucket:</th>
                                      <td>{vehicle.rows[0]["car_insurance_data"]["ScaledRiskBucket"]}</td>
                                    </tr>
                                  : undefined}
                                  {"PosSpdHrsTot" in vehicle.rows[0]['car_insurance_data'] ? 
                                  <tr>
                                    <th style={{paddingRight:'10px'}}>Driving Hours:</th>
                                    <td>{Math.round(vehicle.rows[0]['car_insurance_data']["PosSpdHrsTot"],0)}</td>
                                  </tr>
                                  : undefined}
                                  {"Liab$" in vehicle.rows[0]['car_insurance_data'] ? 
                                  <tr>
                                    <th style={{paddingRight:'10px'}}>Liability $:</th>
                                    <td>${Math.round(vehicle.rows[0]['car_insurance_data']["Liab$"],2)}</td>
                                  </tr>
                                  : undefined}
                                  {"PhysDam$" in vehicle.rows[0]['car_insurance_data'] ? 
                                  <tr>
                                    <th style={{paddingRight:'10px'}}>Physical Damage $:</th>
                                    <td>${Math.round(vehicle.rows[0]['car_insurance_data']["PhysDam$"],2)}</td>
                                  </tr>
                                  : undefined}
                                </tbody></table>
                                <br/>
                              </Flex>
                              <Flex direction="column">
                                <Divider />
                                <Heading level={4}>Risky Driving (7 days)</Heading>
                                {
                                    <table><tbody>
                                      {"SpdEvCnt" in vehicle.rows[0]["car_insurance_data"] ? 
                                      <tr>
                                        <th style={{paddingRight:'10px'}}>Speeding Events (over 90 mph):</th>
                                        <td>{vehicle.rows[0]["car_insurance_data"]["SpdEvCnt"]}</td>
                                      </tr>
                                      : undefined}
                                      {"SevHrdBrkEvCnt" in vehicle.rows[0]["car_insurance_data"] ? 
                                      <tr>
                                        <th style={{paddingRight:'10px'}}>Hard Braking Events (over 13 mph/sec):</th>
                                        <td>{vehicle.rows[0]["car_insurance_data"]["SevHrdBrkEvCnt"]}</td>
                                      </tr>
                                      : undefined}
                                      {"SevHrdAccEvCnt" in vehicle.rows[0]["car_insurance_data"] ? 
                                      <tr>
                                        <th style={{paddingRight:'10px'}}>Hard Acceleration Events (over 13 mph/sec):</th>
                                        <td>{vehicle.rows[0]["car_insurance_data"]["SevHrdAccEvCnt"]}</td>
                                      </tr>
                                      : undefined}
                                      {"TrdDrvEvCnt" in vehicle.rows[0]["car_insurance_data"] ? 
                                      <tr>
                                        <th style={{paddingRight:'10px'}}>Tired Driving Events (over 16 hrs):</th>
                                        <td>{vehicle.rows[0]["car_insurance_data"]["TrdDrvEvCnt"]}</td>
                                      </tr>
                                      : undefined}
                                      {"PctLateNiteHrs" in vehicle.rows[0]["car_insurance_data"] ? 
                                      <tr>
                                        <th style={{paddingRight:'10px'}}>Percent Late Night Hours (1am - 4am):</th>
                                        <td>{Math.round(100*vehicle.rows[0]["car_insurance_data"]["PctLateNiteHrs"])}%</td>
                                      </tr>
                                      : undefined}
                                    </tbody></table>
                                }
                                <br/>
                              </Flex>
                              <Flex direction="column">
                                <Divider />
                                <Heading level={4}>Commercial Coverage (7 days)</Heading>
                                <table><tbody>
                                  {"LiabGigInsMls" in vehicle.rows[0]["car_insurance_data"] ? 
                                  <tr>
                                    <th style={{paddingRight:'10px'}}>Liability Gig Insured Miles:</th>
                                    <td>{Math.round(vehicle.rows[0]["car_insurance_data"]["LiabGigInsMls"],0)}</td>
                                  </tr>
                                  : undefined}
                                  {"LiabGigUninsMls" in vehicle.rows[0]["car_insurance_data"] ? 
                                  <tr>
                                    <th style={{paddingRight:'10px'}}>Liability Gig Uninsured Miles:</th>
                                    <td>{Math.round(vehicle.rows[0]["car_insurance_data"]["LiabGigUninsMls"],0)}</td>
                                  </tr>
                                  : undefined}
                                  {"LiabPersMiles" in vehicle.rows[0]["car_insurance_data"] ? 
                                  <tr>
                                    <th style={{paddingRight:'10px'}}>Liability Personal Miles:</th>
                                    <td>{Math.round(vehicle.rows[0]["car_insurance_data"]["LiabPersMiles"],0)}</td>
                                  </tr>
                                  : undefined}
                                  {"PhysDamGigInsMls" in vehicle.rows[0]["car_insurance_data"] ? 
                                  <tr>
                                    <th style={{paddingRight:'10px'}}>Physical Damage Gig Insured Miles:</th>
                                    <td>{Math.round(vehicle.rows[0]["car_insurance_data"]["PhysDamGigInsMls"],0)}</td>
                                  </tr>
                                  : undefined}
                                  {"PhysDamGigUninsMls" in vehicle.rows[0]["car_insurance_data"] ? 
                                  <tr>
                                    <th style={{paddingRight:'10px'}}>Physical Damage Gig Uninsured Miles:</th>
                                    <td>{Math.round(vehicle.rows[0]["car_insurance_data"]["PhysDamGigUninsMls"],0)}</td>
                                  </tr>
                                  : undefined}
                                  {"PhysDamPersMiles" in vehicle.rows[0]["car_insurance_data"] ? 
                                  <tr>
                                    <th style={{paddingRight:'10px'}}>Physical Damage Personal Miles:</th>
                                    <td>{Math.round(vehicle.rows[0]["car_insurance_data"]["PhysDamPersMiles"],0)}</td>
                                  </tr>
                                  : undefined}
                                </tbody></table>
                                <br/><br/>
                              </Flex>
                            </Flex>

                          </Flex>
                    : 
                      <Flex direction="column">
                        <Text>Error loading vehicle data</Text>
                        <Text>No data found for vehicle</Text>
                      </Flex>
                : 
                  <Flex direction="column">
                    <Text>Error loading vehicle data</Text>
                    <Text>No vehicle data</Text>
                  </Flex>
              : 
                <Flex direction="column">
                  <Text>Error loading vehicle data</Text>
                  <Text>Vehicle not found</Text>
                </Flex>
            }
        </View>
      </Flex>
  );
}
