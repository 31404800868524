//import { useParams } from "react-router-dom";
import React, { useState, useEffect } from 'react'
import { API, Auth } from 'aws-amplify';
import "@aws-amplify/ui-react/styles.css";
import "react-datepicker/dist/react-datepicker.css";
import Loading from '../components/loading';
import Profile from './profile';
import UploadLicense from './uploadlicense';
import UploadLicenseBack from './uploadlicenseback';
import Selfie from './selfie';
import { Button, Heading } from '@aws-amplify/ui-react';

export default function AccountInfo({isHomepage}) {
  //let params = useParams();
  const [is_loading, updateIsLoading] = React.useState(false);
  //const [res, updateRes] = useState({status:"loading...",rows:[]})
  const [updateResponse, updateUpdateResponse] = useState({status:""})
  //const [count, setCount] = useState(0);
  const [name_first, updateNameFirst] = useState('');
  const [name_last, updateNameLast] = useState('');
  const [name_middle, updateNameMiddle] = useState('');
  const [no_middle, updateNoMiddle] = useState(false);
  const [dob, updateDob] = useState('');
  const [must_confirm_info, updateMustConfirmInfo] = useState(false);

  async function fetchAccountInfo() {
    updateIsLoading(true)
    try {
        let session = await Auth.currentSession()      
        let accessToken = session.getAccessToken()
        let jwt = accessToken.getJwtToken()
        const myInit = {queryStringParameters:{ 
            "back_end": process.env.REACT_APP_BACKEND,
            "jwt":jwt,
            "api_endpoint":"licenseupload"
        }};
        const res = await API.get('adminWebTools','/ago',myInit);
        updateNameFirst(res['name_first']);
        updateNameLast(res['name_last']);
        updateNameMiddle(res['name_middle']);
        updateNoMiddle(res['no_middle']);
        updateDob(res['dob']);
        //updateRes(res);
        updateMustConfirmInfo(res['must_confirm_info']);
    }
    catch (e) {
        //const res = {status:"Error retrieving User information - email us at info@joinago.com: "+toString(e)}
        //updateRes(res)
    }
    updateIsLoading(false)
  }

  useEffect(() => {
    fetchAccountInfo();
  },[])

  async function confirmUserInfo()
  {
    updateIsLoading(true)
    try {
        let session = await Auth.currentSession()      
        let accessToken = session.getAccessToken()
        let jwt = accessToken.getJwtToken()
        const myInit = {queryStringParameters:{ 
            "back_end": process.env.REACT_APP_BACKEND,
            "jwt":jwt,
            "api_endpoint":"confirminfo",
            "name_first":name_first,
            "name_middle":name_middle,
            "no_middle":no_middle,
            "name_last":name_last,
            "dob":dob
        }};
        const res = await API.get('adminWebTools','/ago',myInit)
        updateUpdateResponse(res);
        fetchAccountInfo()
        if(typeof isHomepage==='boolean')
        {
          if(isHomepage)
          {
            window.location.reload();
          }
        }
    }
    catch (e) {
        //const res = {status:"Error retrieving User information - email us at info@joinago.com: "+toString(e)}
        //updateRes(res)
      updateIsLoading(false)
    }
  }

  return (
    <main>
        <div style={{padding:"1rem"}}>
          <Heading level={2}>Account Info</Heading>
          <p>View and update your account information below.</p>
        </div>
        {
          is_loading ? <Loading /> : <div>
            <table>
              <tr><th>First Name: </th><td><input type='text' onChange={e => updateNameFirst(e.target.value)} value={name_first} /></td></tr>
              {no_middle === true ? 
                <Button style={{border:'none', fontStyle: 'italic', marginLeft:'-15px'}} onClick={() => updateNoMiddle(false)}>I HAVE NO MIDDLE NAME (click to change)</Button> 
                : 
                <tr><th>Middle Name: </th><td><input type='text' onChange={e => updateNameMiddle(e.target.value)} value={name_middle} /></td></tr>
                      
              }
              {name_middle==="" ? no_middle === true ? undefined : <Button style={{border:'none', fontStyle: 'italic', marginLeft:'-15px'}} onClick={() => updateNoMiddle(true)}> Click here if you don't have a middle name </Button> : undefined}
              <tr><th>Last Name: </th><td><input type='text' onChange={e => updateNameLast(e.target.value)} value={name_last} /></td></tr>
              <tr><th>Date of Birth:<br/><span style={{fontStyle:'italic', fontWeight:'normal'}}>YYYY-MM-DD</span> </th><td><input type='text' onChange={e => updateDob(e.target.value)} value={dob} /></td></tr>
            </table>
            <br/>
            {must_confirm_info ? <Button style={{paddingTop:5, paddingBottom:5, paddingLeft:30, paddingRight:30, backgroundColor:"#092F50",color:"#fff", borderRadius:5}} onClick={() => confirmUserInfo()}>Confirm name and dob (required)</Button> : <Button style={{paddingTop:5, paddingBottom:5, paddingLeft:30, paddingRight:30, backgroundColor:"#092F50",color:"#fff", borderRadius:5}} onClick={() => confirmUserInfo()}>Save name and dob updates</Button> }
            <br/>{updateResponse['status']}
            <br/><br/><br/><br/>
            <UploadLicense />
            <UploadLicenseBack />
            <Selfie />
            <Profile />
            <div style={{padding:"1rem"}}>
              <Heading level={2}>Phone Number</Heading>
              <p>Your phone number can be viewed in the left drawer under 'Account' and cannot be changed at this time.</p>
            </div>
          </div>
        }
    </main>
  );
}