import React, { useState, useEffect } from 'react'
import { API, Auth } from 'aws-amplify';
import { NavLink} from "react-router-dom";
import "@aws-amplify/ui-react/styles.css";
import "react-datepicker/dist/react-datepicker.css";
import { Flex, Divider, Text, Heading, View, Button} from '@aws-amplify/ui-react';
import { AgGridReact } from 'ag-grid-react'; // React Data Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the grid
import Loadingmini from '../components/loadingmini';

export default function Vehiclecommandhistory({vehicle}) {
  const [is_loading, updateIsLoading] = React.useState(false);
  const [count, setCount] = useState(0);
  const [res, updateRes] = useState({status:"Contact support if this message stays for more than 30 seconds",rows:[]})
  const columns = [
      {
          field: 'created',
          filter: true,
      },
      {
          field: 'command',
          filter: true,
          width: 150
      },
      {
          field: 'send_status',
          filter: true,
          width: 150
      },
      {
          field: 'command_status',
          filter: true,
          width: 150
      },
      {
          field: 'response',
          filter: true
      },
      {
          field: 'error_info',
          filter: true
      },
      {
          field: 'name_first',
          filter: true
      },
      {
          field: 'name_last',
          filter: true
      },
      {
          field: 'duration',
          filter: true
      },
      {
          field: 'carname',
          filter: true
      },
      {
          field: 'model',
          filter: true
      },
      {
          field: 'year',
          filter: true
      },
      {
          field: 'api',
          filter: true
      }
  ];
  

  async function fetchPageData() {
    updateIsLoading(true)
    if(vehicle)
    {
        if(vehicle['rows'])
        {
            if(vehicle['rows'][0])
            {
                if(vehicle['rows'][0]['car_id'])
                {
                    console.log('vehiclecommandhistory vehicle '+ vehicle['rows'][0]['car_id'])
                    let session = await Auth.currentSession()      
                    let accessToken = session.getAccessToken()
                    let jwt = accessToken.getJwtToken()
                    let user = await Auth.currentAuthenticatedUser(); 
                    const myInit = {queryStringParameters:{ 
                        "back_end": process.env.REACT_APP_BACKEND,
                        "jwt": jwt,
                        "api_endpoint":"vehiclecommandhistory",
                        "vehicle_id":vehicle['rows'][0]['car_id']
                    }};
                    try {
                        const res = await API.get('adminWebTools','/ago',myInit)
                        console.log('vehiclecommandhistory response: '+JSON.stringify(res))
                        updateRes(res)
                        updateIsLoading(false)
                    }
                    catch (e) {
                        console.log('vehiclecommandhistory error: '+toString(e));
                        updateIsLoading(false)
                    }
                }
            }
        }
    }
  }

  useEffect(() => {
    fetchPageData()
    },[count])



  return (
      <Flex
        direction="column"
      >
        <View style={{padding:"1rem"}}>
            <Divider />
            {
                is_loading ? <Loadingmini /> : 
                <Flex 
                    direction='column'
                >
                    <Heading level={2}>Vehicle Command History</Heading>
                    <Text style={{width:'100%'}}>This is the history of commands sent to this vehicle</Text>
                    <View>
                        <Button onClick={() => setCount(count + 1)}>
                            PRESS TO GET LATEST UPDATES
                        </Button>
                    </View>
                    <Divider />
                    <Text><i>Hint: To search, long-click on mobile or click top-right on pc.</i></Text>
                    <View style={{width:"100%"}}>
                        <View 
                            className="ag-theme-quartz" // applying the grid theme
                            style={{ height: 500 }} // the grid will fill the size of the parent container
                        >
                                <AgGridReact
                                    rowData={res.rows}
                                    columnDefs={columns}
                                    rowSelection={'multiple'}
                                    paginationPageSize={500}
                                    paginationPageSizeSelector={[10, 25, 50]}
                                    enableCellTextSelection={true}
                                />
                        </View>
                    </View>
                    <Divider />
                </Flex>
            }
        </View>
      </Flex>
  );
}
