import React, { useState } from 'react'
import "@aws-amplify/ui-react/styles.css";
import "react-datepicker/dist/react-datepicker.css";
import Loadingmini from '../components/loadingmini';
import { Flex, Divider, View, Text, Heading, Button} from '@aws-amplify/ui-react';


export default function Userriskydriving({user}) {
  const [is_loading, updateIsLoading] = React.useState(false);

  function formatDate(UNIX_timestamp){
    var a = new Date(UNIX_timestamp * 1000);
    var months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
    var year = a.getFullYear();
    var month = months[a.getMonth()];
    var date = a.getDate();
    var hour = a.getHours();
    //var min = a.getMinutes();
    //var sec = a.getSeconds();
    var days = ['Sunday','Monday','Tuesday','Wednesday','Thursday','Friday','Saturday']
    var day = days[a.getDay()];
    if(hour > 12)
    {
      var thour = hour - 12
      var pmam = ' PM';
    } else {
      if(hour < 12)
      {
        if(hour < 1)
        {
          var thour = '12';
          var pmam = ' AM';
        } else {
          var thour = hour;
          var pmam = ' AM';
        }
      } else {
        var thour = '12';
        var pmam = ' PM';
      }
    }
    var time = day + ', ' + month + ' ' + date + ', ' + year + ' - ' + thour + pmam;
    return time;
  }

  return (
    is_loading ? <Loadingmini /> : 
      <Flex
        direction="column"
      >
        <View style={{padding:"1rem"}}>
          <Divider />
          {
            user ?
              user.resp ?
                user.resp[0] ?  
                  user.resp[0]["driver_insurance_data"] === false || user.resp[0]["driver_insurance_data"] === null ? 
                  <Flex direction="column">
                      <Heading level={2}>No Driving Behavior Data</Heading>
                      <Text>If this driver is new, it may take up to a week for new data to populate.</Text>
                      <Text>If you aren't getting your insurance through <a href='https://agorisk.com/'>Ago</a>, you could be saving a bundle.</Text>
                  </Flex>
                  :
                  "TotMls" in user.resp[0]["driver_insurance_data"] === false ? //just a general check... since the line above doesn't seem to work
                  <Flex direction="column">
                      <Heading level={2}>No Driving Behavior Data</Heading>
                      <Text>If this driver is new, it may take up to a week for new data to populate.</Text>
                      <Text>If you aren't getting your insurance through <a href='https://agorisk.com/'>Ago</a>, you could be saving a bundle.</Text>
                  </Flex>
                  :
                  <Flex direction="column">
                      <Heading level={2}>Risky Driving</Heading>
                      <Flex wrap="wrap" gap="2rem" justifyContent="center">
                        <Flex direction="column">
                          <Divider />
                          <table><tbody>

                          {"period_start" in user.resp[0] ? 
                            <tr>
                              <th style={{paddingRight:'10px'}}>Period Start:</th>
                              <td>{formatDate(user.resp[0]["period_start"])}</td>
                            </tr>
                            : undefined}
                            {"period_end" in user.resp[0] ? 
                            <tr>
                              <th style={{paddingRight:'10px'}}>Period End:</th>
                              <td>{formatDate(user.resp[0]["period_end"])}</td>
                            </tr>
                            : undefined}
                            {"ScaledRiskBucket" in user.resp[0]["driver_insurance_data"] ? 
                              <tr>
                                <th style={{paddinRight:'10px', paddingLeft:'10px', paddingTop:'5px', paddingBottom:'5px'}}>Safety Bucket:</th>
                                <td style={{paddinRight:'10px', paddingLeft:'10px', paddingTop:'5px', paddingBottom:'5px'}}>{user.resp[0]["driver_insurance_data"]["ScaledRiskBucket"]}</td>
                              </tr>
                            : undefined}
                            {"SevHrdAccEvCnt" in user.resp[0]["driver_insurance_data"] ? 
                              <tr>
                                <th style={{paddinRight:'10px', paddingLeft:'10px', paddingTop:'5px', paddingBottom:'5px'}}>Accelerate over 13 mph/sec:</th>
                                <td style={{paddinRight:'10px', paddingLeft:'10px', paddingTop:'5px', paddingBottom:'5px'}}>{Math.round(user.resp[0]["driver_insurance_data"]["SevHrdAccEvCnt"])}</td>
                              </tr>
                            : undefined}
                            {"SevHrdBrkEvCnt" in user.resp[0]["driver_insurance_data"] ? 
                              <tr>
                                <th style={{paddinRight:'10px', paddingLeft:'10px', paddingTop:'5px', paddingBottom:'5px'}}>Brakes over 13 mph/sec:</th>
                                <td style={{paddinRight:'10px', paddingLeft:'10px', paddingTop:'5px', paddingBottom:'5px'}}>{Math.round(user.resp[0]["driver_insurance_data"]["SevHrdBrkEvCnt"])}</td>
                              </tr>
                            : undefined}
                            {"Spd90to95EvCnt" in user.resp[0]["driver_insurance_data"] ? 
                              <tr>
                                <th style={{paddinRight:'10px', paddingLeft:'10px', paddingTop:'5px', paddingBottom:'5px'}}>Speeding 90-95 mph:</th>
                                <td style={{paddinRight:'10px', paddingLeft:'10px', paddingTop:'5px', paddingBottom:'5px'}}>{Math.round(user.resp[0]["driver_insurance_data"]["Spd90to95EvCnt"])}</td>
                              </tr>
                            : undefined}
                            {"Spd95to100EvCnt" in user.resp[0]["driver_insurance_data"] ? 
                              <tr>
                                <th style={{paddinRight:'10px', paddingLeft:'10px', paddingTop:'5px', paddingBottom:'5px'}}>Speeding 95-100 mph:</th>
                                <td style={{paddinRight:'10px', paddingLeft:'10px', paddingTop:'5px', paddingBottom:'5px'}}>{Math.round(user.resp[0]["driver_insurance_data"]["Spd95to100EvCnt"])}</td>
                              </tr>
                            : undefined}
                            {"Spd100PlusEvCnt" in user.resp[0]["driver_insurance_data"] ? 
                              <tr>
                                <th style={{paddinRight:'10px', paddingLeft:'10px', paddingTop:'5px', paddingBottom:'5px'}}>Speeding 100+ mph:</th>
                                <td style={{paddinRight:'10px', paddingLeft:'10px', paddingTop:'5px', paddingBottom:'5px'}}>{Math.round(user.resp[0]["driver_insurance_data"]["Spd100PlusEvCnt"])}</td>
                              </tr>
                            : undefined}
                            {"Trd16to20EvCnt" in user.resp[0]["driver_insurance_data"] ? 
                              <tr>
                                <th style={{paddinRight:'10px', paddingLeft:'10px', paddingTop:'5px', paddingBottom:'5px'}}>Drove 16-20 Hours Straight:</th>
                                <td style={{paddinRight:'10px', paddingLeft:'10px', paddingTop:'5px', paddingBottom:'5px'}}>{Math.round(user.resp[0]["driver_insurance_data"]["Trd16to20EvCnt"])}</td>
                              </tr>
                            : undefined}
                            {"Trd20PlusEvCnt" in user.resp[0]["driver_insurance_data"] ? 
                              <tr>
                                <th style={{paddinRight:'10px', paddingLeft:'10px', paddingTop:'5px', paddingBottom:'5px'}}>Drove 20+ Hours Straight:</th>
                                <td style={{paddinRight:'10px', paddingLeft:'10px', paddingTop:'5px', paddingBottom:'5px'}}>{Math.round(user.resp[0]["driver_insurance_data"]["Trd20PlusEvCnt"])}</td>
                              </tr>
                            : undefined}
                            {"PctLateNiteHrs" in user.resp[0]["driver_insurance_data"] ? 
                              <tr>
                                <th style={{paddinRight:'10px', paddingLeft:'10px', paddingTop:'5px', paddingBottom:'5px'}}>% Drove 1am - 4am:</th>
                                <td style={{paddinRight:'10px', paddingLeft:'10px', paddingTop:'5px', paddingBottom:'5px'}}>{Math.round(100*user.resp[0]["driver_insurance_data"]["PctLateNiteHrs"])}%</td>
                              </tr>
                            : undefined}
                          </tbody></table>
                        </Flex>
                      </Flex>
                  </Flex>
                : 
                  <Flex direction="column">
                    <Text>Error loading user data</Text>
                    <Text>No data found for user</Text>
                  </Flex>
              : 
                <Flex direction="column">
                  <Text>Error loading user data</Text>
                  <Text>No user data</Text>
                </Flex>
            : 
              <Flex direction="column">
                <Text>Error loading user data</Text>
                <Text>User not found</Text>
              </Flex>
          }
        </View>
      </Flex>
  );
}
