import { useParams } from "react-router-dom";
import React, { useState, useEffect } from 'react'
import { API, Auth } from 'aws-amplify';
import "@aws-amplify/ui-react/styles.css";
import "react-datepicker/dist/react-datepicker.css";
import Loadingmini from '../components/loadingmini';
import { Flex, Divider, View, Text, Heading, Button} from '@aws-amplify/ui-react';

export default function Vehicleserviceraccess({vehicle}) {
  const [is_loading, updateIsLoading] = React.useState(false);
  const [can_provide_servicer_access, updateCanProvideServicerAccess] = React.useState(false);  // FEATURE CURRENTLY DISABLED
  const [show_provide_servicer_access, updateShowProvideServicerAccess] = React.useState(false);
  const [show_servicer_access_confirmed, updateShowServicerAccessConfirmed] = React.useState(false);
  const [servicerPhone, updateServicerPhone] = React.useState("");
  const [servicerPin, updateServicerPin] = React.useState("");
  const [show_error_handler, updateShowErrorHandler] = React.useState(false);
  const [error_message, updateErrorMessage] = React.useState("");

  async function showServicerAccessConfirmed() {
    updateShowServicerAccessConfirmed(true);
  }
  async function hideServicerAccessConfirmed() {
    updateShowServicerAccessConfirmed(false);
  }
  async function showProvideServicerAccess() {
    updateShowProvideServicerAccess(true);
  }
  async function hideProvideServicerAccess() {
    updateShowProvideServicerAccess(false);
  }
  async function hideErrorHandler() {
    updateShowErrorHandler(false);
  }
  async function showErrorHandler() {
    updateShowErrorHandler(true);
  }

  async function submitServicerAccess()
  {
    updateIsLoading(true);
    let session = await Auth.currentSession()      
    let accessToken = session.getAccessToken()
    let jwt = accessToken.getJwtToken()
    const myInit = {queryStringParameters:{ 
        back_end: process.env.REACT_APP_BACKEND, 
        jwt:jwt,
        api_endpoint:"servicer",
        command:"PROVIDE_ACCESS",
        vehicle_id: vehicle['rows'][0]['car_id'],
        phone:servicerPhone,
        access_until:0
    }};
    try {
        const res = await API.get('adminWebTools','/ago',myInit)
        console.log(JSON.stringify(res))
        if(res['status']=='success')
        {
          updateServicerPin(res['pin']);
          hideProvideServicerAccess();
          showServicerAccessConfirmed();
        } else {
          // a nice alert library would be good here
          updateErrorMessage(res['status']);
          showErrorHandler();
        }
        updateIsLoading(false)
        return
    }
    catch (e) {
        const res = {status:"Error performing task or user not permissioned - email us at developer@agorisk.com",rows:[]};
        updateIsLoading(false)
        return
    }
  }

  return (
    is_loading ? <Loadingmini /> : 
      <Flex
        direction="column"
      >
          {
              vehicle ?
                vehicle.rows ?
                  vehicle.rows[0] ?  
                      <Flex direction="column">
                          {show_servicer_access_confirmed ? 
                            <View style={{width:'100%', height:'100%', position:'fixed', top:0, left:0, right:0, bottom:0, backgroundColor:'black', opacity:'0.92', justifyContent:'center', alignItems:'center', zIndex:100}}>
                              <View style={{position:'fixed', top:'20%', left:'50%',width:'600px',marginLeft:'-300px', justifyContent:'center',alignItems:'center', backgroundColor:'white', paddingTop:'30px', paddingBottom:'30px' }}>
                                <Heading level={2} style={{textAlign:'center', fontWeight:'bold'}}>Access Granted</Heading>
                                <Text style={{textAlign:'center'}}>Text {servicerPin} to (312) 847-4755 from {servicerPhone} to access this vehicle for the next 24 hours.</Text>
                                <Text style={{textAlign:'center'}}><Button onClick={() => hideServicerAccessConfirmed()} style={{paddingTop:5, paddingBottom:5, paddingLeft:30, paddingRight:30, backgroundColor:"transparent",color:"#44BECB", borderRadius:5, width:'200px'}}>EXIT</Button></Text>
                              </View>
                            </View>
                          : undefined}
                          {show_provide_servicer_access ? 
                            <View style={{width:'100%', height:'100%', position:'fixed', top:0, left:0, right:0, bottom:0, backgroundColor:'black', opacity:'0.92', justifyContent:'center', alignItems:'center', zIndex:100}}>
                              <View style={{position:'fixed', top:'20%', left:'50%',width:'600px',marginLeft:'-300px', justifyContent:'center',alignItems:'center', backgroundColor:'white', paddingTop:'30px', paddingBottom:'30px' }}>
                                <Heading level={2} style={{textAlign:'center', fontWeight:'bold'}}>Provide Servicer Access</Heading>
                                <Text style={{textAlign:'center'}}>Provides 24 hours access by texting PIN to (312) 847-4755</Text>
                                <Text style={{textAlign:'center'}}>Servicer's Phone Number (+15555555555):<br/><input type='text' onChange={e => updateServicerPhone(e.target.value)} value={servicerPhone} /></Text>
                                <br/>
                                <Text style={{textAlign:'center'}}><Button onClick={() => submitServicerAccess()} style={{paddingTop:5, paddingBottom:5, paddingLeft:30, paddingRight:30, backgroundColor:"transparent",color:"#092F50", borderRadius:5, width:'200px'}}>SUBMIT</Button></Text>
                                <Text style={{textAlign:'center'}}><Button onClick={() => hideProvideServicerAccess()} style={{paddingTop:5, paddingBottom:5, paddingLeft:30, paddingRight:30, backgroundColor:"transparent",color:"#44BECB", borderRadius:5, width:'200px'}}>CANCEL</Button></Text>
                              </View>
                            </View>
                          : undefined}
                          {
                            show_servicer_access_confirmed===false && show_provide_servicer_access===false ?
                              can_provide_servicer_access ?
                                <Button onClick={() => showProvideServicerAccess()}>GRANT SMS ACCESS</Button>
                              : <Button backgroundColor={'#9E9E9E'}>SMS ACCESS UNAVAILABLE</Button>
                            : undefined
                          }
                      </Flex>
                  : 
                    <Flex direction="column">
                      <Text>Error loading vehicle data</Text>
                      <Text>No data found for vehicle</Text>
                    </Flex>
                : 
                  <Flex direction="column">
                    <Text>Error loading vehicle data</Text>
                    <Text>No vehicle data</Text>
                  </Flex>
              : 
                <Flex direction="column">
                  <Text>Error loading vehicle data</Text>
                  <Text>Vehicle not found</Text>
                </Flex>
            }
            {show_error_handler ? 
              <div style={{width:'100%', height:'100%', position:'fixed', top:0, left:0, right:0, bottom:0, backgroundColor:'black', opacity:'0.92', justifyContent:'center', alignItems:'center', zIndex:100}}>
                <div style={{position:'fixed', top:'20%', left:'50%',width:'600px',marginLeft:'-300px', justifyContent:'center',alignItems:'center', backgroundColor:'white', paddingTop:'30px', paddingBottom:'30px' }}>
                  <h2 style={{textAlign:'center', fontWeight:'bold'}}>Whoops!</h2>
                  <p style={{textAlign:'center'}}>There was an error processing your request.</p>
                  <p style={{textAlign:'center'}}>{error_message}</p>
                  <p style={{textAlign:'center'}}><Button onClick={() => hideErrorHandler()} style={{paddingTop:5, paddingBottom:5, paddingLeft:30, paddingRight:30, backgroundColor:"transparent",color:"#44BECB", borderRadius:5, width:'200px'}}>EXIT</Button></p>
                </div>
              </div>
            : undefined}
      </Flex>
  );
}
