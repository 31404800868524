import { useParams } from "react-router-dom";
import React, { useState, useEffect } from 'react'
import { API, Auth } from 'aws-amplify';
import { Text, View, Flex, Divider} from '@aws-amplify/ui-react';
import Loadingmini from '../components/loadingmini';
import { Heading, Button } from '@aws-amplify/ui-react';
import Userinfo from './userinfo';
import Userinsurancedata from './userinsurancedata';
import Userinsurancebuckets from './userinsurancebuckets';
import Userriskydriving from './userriskydriving';
import Usercommercialplatforms from './usercommercialplatforms';
import Usercommercialplatformaccounts from './usercommercialplatformaccounts';
import Usercommercialplatformactivity from './usercommercialplatformactivity';
import Userstatushistory from "./userstatushistory";
import Userchathistory from "./userchathistory";
import Userreservations from "./userreservations";
import Usercredits from "./usercredits";


const roundBack = (date,hours) => {
  date.setHours(date.getHours() - hours);
  date.setMinutes(0, 0, 0);
  return date;
}



export default function User({paramUserId}) {
  let params = useParams();
  let tuserId = false
  if(paramUserId)
  {
    tuserId = paramUserId;
  } else {
    tuserId = params.userId;
  }
  const [userId, updateUserId] = React.useState(tuserId);
  const [user, updateUser] = useState({status:"If this message stays - email us at info@joinago.com",rows:[]})
  const [is_loading, updateIsLoading] = React.useState(false);
  const [count, setCount] = useState(0);
  const [commercialPlatformActivityMaxDate, setCommercialPlatformActivityMaxDate] = useState(roundBack(new Date(),-0));
  const [commercialPlatformActivityMaxDateIsDefault, setCommercialPlatformActivityMaxDateIsDefault] = useState(true);
  const [showDataPacket, updateShowDataPacket] = React.useState(false);

  
  const dateString = (d) => {
    var s = d.getFullYear() + "-" +
    ("0" + (d.getMonth()+1)).slice(-2) + "-" +
    ("0" + d.getDate()).slice(-2)
    return s;
  }

  function selectCommercialPlatformActivityMaxDate(date)
  {
    setCommercialPlatformActivityMaxDateIsDefault(false);
    setCommercialPlatformActivityMaxDate(date);
    setCount(count + 1)
  }

  useEffect(() => {
    const fetchUserData = async () => {
      var thisUserId = userId;
      if(paramUserId > 0)
      {
        thisUserId = paramUserId;
        updateUserId(paramUserId);
      }
      updateIsLoading(true)
      let session = await Auth.currentSession()      
      let accessToken = session.getAccessToken()
      var jwt = accessToken.getJwtToken()
      if(commercialPlatformActivityMaxDateIsDefault)
      {
        var initProps = { 
          back_end: process.env.REACT_APP_BACKEND, 
          api_endpoint:"drivers",
          jwt:jwt,
          driver_id: thisUserId
        }
      } else {
        //console.log('commercialPlatformActivityMaxDate:'+commercialPlatformActivityMaxDate);
        var dateStringResult = dateString(commercialPlatformActivityMaxDate);
        //console.log('dateStringResult:'+dateStringResult);
        var initProps = { 
          back_end: process.env.REACT_APP_BACKEND, 
          api_endpoint:"users",
          jwt:jwt,
          driver_id: thisUserId,
          commercialPlatformActivityMaxDate:dateStringResult
        }
      }
      const myInit = {queryStringParameters:initProps};
      
      //console.log(JSON.stringify('myInit: '+JSON.stringify(myInit))); //temp

      try {
        //console.log("driver_id:  " + thisUserId);
        const res = await API.get('adminWebTools','/ago',myInit);
        
        
        //console.log('updateUser: '+ JSON.stringify(res)) //temp
        
        
        updateUser(res);
        updateIsLoading(false)
        return
      }
      catch (e) {
          const res = {status:"Error retrieving Users or User not permissioned - email us at info@joinago.com",rows:[]};
          updateUser(res);
          updateIsLoading(false)
          return
      }
    }

    fetchUserData()

  },[count,params,paramUserId])

  
  return (
    is_loading ? <Loadingmini /> : 
    <Flex //  https://ui.docs.amplify.aws/react/components/flex#demo
      direction="column"
    >
      <View style={{padding:"1rem"}}>
          <Flex>
            {
              user === false ? <Heading level={1}>Driver {userId}</Heading> :
              user.resp === false ? <Heading level={1}>Driver {userId}</Heading> :
              typeof(user.resp) === 'undefined' ?  <Heading level={1}>Driver {userId}</Heading> :
              user.resp[0] === false || user.resp[0] === null ? <Heading level={1}>Driver {userId}</Heading> :
              typeof(user.resp[0]) === 'undefined' ?  <Heading level={1}>Driver {userId}</Heading> :
              'name_first' in user.resp[0] === false ? <Heading level={1}>Driver {userId}</Heading> :
              user.resp[0]["name_first"] === "" ? <Heading level={1}>Driver {userId}</Heading> :
              <Heading level={1}>{user.resp[0]["name_first"]} {user.resp[0]["name_last"]}</Heading>
            }
          </Flex>
          <Button onClick={() => setCount(count + 1)}>
              PRESS TO GET LATEST UPDATES
          </Button>
          <Divider />
          <Flex wrap="wrap" justifyContent="center" gap="1rem">
              <Userinfo user={user} getUpdatedUserData={() => setCount(count + 1)}/>
              <Userstatushistory userId={userId} />
              <Userreservations userId={userId} />
              <Userchathistory userId={userId} />
              <Usercredits userId={userId} />
              <Userinsurancedata user={user} />
              <Userinsurancebuckets user={user} />
              <Userriskydriving user={user} />
              <Usercommercialplatforms user={user} />
              <Usercommercialplatformaccounts user={user} />
              <Usercommercialplatformactivity user={user} selectCommercialPlatformActivityMaxDate={selectCommercialPlatformActivityMaxDate} commercialPlatformActivityMaxDate={commercialPlatformActivityMaxDate} />
          </Flex>
          {user === false ? undefined:
            user.resp === false ? undefined:
            typeof(user.resp) === 'undefined' ?  undefined:
            user.resp[0] === false ? undefined:
            <Flex direction="column">
              <Divider />
              {
                showDataPacket ?
                  <Flex direction="column">
                    <Heading level={2}>Data Packet</Heading>
                    <pre id="json"><p>{JSON.stringify(user,null, 4)}</p></pre>
                    <Button onClick={() => updateShowDataPacket(false)}>
                      Hide Data Packet
                    </Button>
                  </Flex>
                : 
                  <Button onClick={() => updateShowDataPacket(true)}>
                    User Data Packet
                  </Button>
              }
              <br/><br/><br/><br/>
            </Flex>
          }
      </View>
    </Flex>
  );
}