
import { useParams } from "react-router-dom";
import React, { useState, useEffect } from 'react'
import { API, Auth } from 'aws-amplify';
import { SliderField, Heading, Flex, Button, SelectField, Text, View, Divider} from '@aws-amplify/ui-react';
import "@aws-amplify/ui-react/styles.css";
import Timeline, {
  TimelineHeaders,
  SidebarHeader,
  TodayMarker,
  TimelineMarkers
} from 'react-calendar-timeline/lib'
import 'react-calendar-timeline/lib/Timeline.css'
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import Loadingmini from '../components/loadingmini';
import Reservation from './reservation';
import {useNavigate} from 'react-router-dom';




export default function Reservations() {
  let params = useParams();
  const roundBack = (date,hours) => {
    date.setHours(date.getHours() - hours);
    date.setMinutes(0, 0, 0);
    return date;
  }
  const dateString = (d) => {
      var s = d.getFullYear() + "-" +
      ("0" + (d.getMonth()+1)).slice(-2) + "-" +
      ("0" + d.getDate()).slice(-2) + " " +
      ("0" + d.getHours()).slice(-2) + ":" +
      ("0" + d.getMinutes()).slice(-2)
      return s;
  }
  const keys = {
    groupIdKey: "id",
    groupTitleKey: "title",
    groupRightTitleKey: "rightTitle",
    itemIdKey: "id",
    itemTitleKey: "title",
    itemDivTitleKey: "title",
    itemGroupKey: "group",
    itemTimeStartKey: "start_time",
    itemTimeEndKey: "end_time",
    groupLabelKey: "title"
  };
  const [count, setCount] = useState(0);
  const [res_id,setRes_id] = useState("")
  const [reservations, updateReservations] = useState({
    status:"If this message stays - email us at info@joinago.com",
    tz_choices:["ET"],
    drivers:["0:Not A Scheduled Driver"],
    state_count:-1,
    html:"",
    options:"",
    cars: [],
    rsvns: [],
    cars_out: [{ id: 1, title: 'vehicle...' }],
    rsvns_out: [],
    display_start: Date.now()-168*3600000,
    display_end: Date.now()+3*168*3600000,
    id_map: new Map()
  })
  const [homeTz,setHomeTz] = useState("ET")
  const [searchType,setSearchType] = useState("existing")
  const [driver,setDriver] = useState("0:Not A Scheduled Driver")
  const [windowStartDate, setWindowStartDate] = useState(roundBack(new Date(),12));
  const [resStartDate, setResStartDate] = useState(roundBack(new Date(),-1));
  const [resEndDate, setResEndDate] = useState(roundBack(new Date(),-25));
  const [is_loading, updateIsLoading] = React.useState(false);
  const [windowDays,setWindowDays] = useState(15);
  const [showReservationPage, updateShowReservationPage] = React.useState(false);
  const [showAdvancedSearch, updateShowAdvancedSearch] = React.useState(false);
  const [showAdvancedSearchButton, updateShowAdvancedSearchButton] = React.useState(false);
  const [displaySideDock, updateDisplaySideDock] = React.useState(false);
  const navigate = useNavigate();
  
  
  useEffect(() => {
    const fetchUser = async () => {
      let session = await Auth.currentSession()      
      let user = await Auth.currentAuthenticatedUser(); 
      if(user.signInUserSession.accessToken.payload["cognito:groups"][0] ==='admin')
      {
        updateShowAdvancedSearchButton(true)
      }
    }
    fetchUser();
  }, []);

  useEffect(() => {
    const fetchReservationsData = async () => {
      updateIsLoading(true)
      let session = await Auth.currentSession()      
      let accessToken = session.getAccessToken()
      var jwt = accessToken.getJwtToken()
      let user = await Auth.currentAuthenticatedUser(); 
      const myInit = {queryStringParameters:{ 
        back_end: process.env.REACT_APP_BACKEND,
        jwt: jwt,
        api_endpoint: "reservations",
        user_pool_id: user.pool.userPoolId,
        search_type: searchType,
        tz: homeTz,
        window_startS: dateString(windowStartDate),
        window_days: windowDays,
        driver:driver,
        res_startS: dateString(resStartDate),
        res_endS: dateString(resEndDate)
      }};
      
      //console.log('getReservations init: '+JSON.stringify(myInit));

      try {
        const res = await API.get('adminWebTools','/ago',myInit)

          res.state_count = count
        
          const cars_out = [];
          for (let i = 0; i < res.cars.length; i++) {
            cars_out.push({
              id: res.cars[res.cars.length-1-i].id,
              title: res.cars[res.cars.length-1-i].name.substring(0,30),
            });
          };
          res.cars_out = [].concat(cars_out)

          const rsvns_out = []
          res.display_start = Date.now()-24*3600000;
          res.display_end =  Date.now()+24*3600000;
          res.id_map = new Map()
          let item_id = 1
          for (let i = 0; i < res.rsvns.length; i++) {
            res.display_start = Math.min(res.display_start,res.rsvns[i].start_unix*1000)
            res.display_end = Math.max(res.display_end,res.rsvns[i].end_unix*1000)
            let l_title = "X-" + res.rsvns[i].status + "-X"
            let bgc = 'Red'
            if (res.rsvns[i].status === 'pre') 
            {
              bgc = 'Plum'
              l_title = "Temp Blocked"
            } 
            else if (res.rsvns[i].status === 'deposit') 
            {
              bgc = 'LightSalmon'
              l_title = res.rsvns[i].driverName  + ",S:" + res.rsvns[i].startString + ",E:" + res.rsvns[i].endString + ",#:" + res.rsvns[i].res_id + "," + res.rsvns[i].status
            } 
            else if (res.rsvns[i].status === 'price') 
            {
              bgc = 'MediumAquaMarine'
              l_title = res.rsvns[i].driverName  + ",S:" + res.rsvns[i].startString + ",E:" + res.rsvns[i].endString + ",#:" + res.rsvns[i].res_id + "," + res.rsvns[i].status
            } 
            else if (res.rsvns[i].status === 'remaining')
            {
              bgc = 'MediumAquaMarine'
              l_title = res.rsvns[i].driverName  + ",S:" + res.rsvns[i].startString + ",E:" + res.rsvns[i].endString + ",#:" + res.rsvns[i].res_id + "," + res.rsvns[i].status
            }
            else if (res.rsvns[i].status === 'extension')
            {
              bgc =  'AquaMarine' 
              l_title = res.rsvns[i].driverName  + ",S:" + res.rsvns[i].startString + ",E:" + res.rsvns[i].endString + ",#:" + res.rsvns[i].res_id + "," + res.rsvns[i].status
            }
            else if (res.rsvns[i].status === 'available')
            {
              bgc =  'DarkGrey' 
              l_title = 'available'
            }
            else if (res.rsvns[i].status === 'returned')
            {
              bgc =  'DarkGreen'
              l_title = 'returned'
            }
            else if (res.rsvns[i].status === 'buffer')
            {
              bgc =  'DarkOliveGreen' 
              l_title = ''
            };
            rsvns_out.push({
              id: item_id,
              group: res.rsvns[i].carId,
              start_time: res.rsvns[i].start_unix*1000,
              end_time: res.rsvns[i].end_unix*1000,
              title: l_title ,
              canMove: false,
              canResize: false,
              canChangeGroup: false,
              itemProps: {
                style: {
                  background: bgc,
                  padding:'0px'
                }
              }
            });
            res.id_map.set(item_id.toString(),res.rsvns[i].res_id)
            item_id = item_id + 1
            if (res.rsvns[i].end_unix !== res.rsvns[i].block_end_unix) {
              rsvns_out.push({
                id: item_id,
                group: res.rsvns[i].carId,
                start_time: res.rsvns[i].end_unix*1000,
                end_time: res.rsvns[i].block_end_unix*1000,
                title: "Blocked For Renewal",
                canMove: false,
                canResize: false,
                canChangeGroup: false,
                itemProps: {
                  style: {
                    background: 'LightBlue',
                    padding:'0px'
                  }
                }
              });
              res.id_map.set(item_id.toString(),res.rsvns[i].res_id)
              item_id = item_id + 1
            };
          };
          res.rsvns_out = [].concat(rsvns_out)
          
          res.display_start = Date.now()-168*3600000
          res.display_end = Date.now()+3*168*3600000

          updateReservations(res);

          /* crazily this switch of res_id enforces flushing and updating of timeline data - so dont alter */
          /* also dont modify the showing of the res_id in html where it is */
          /* clearly need to figure this out or switch from using calendar-timeline at some point */

          if (res_id === 'x') {
            setRes_id("-")
          }
          else
          {
            setRes_id("x")
          }
          updateIsLoading(false)
      }
      catch (e) {
          const res = {status:"Error retrieving Reservations or User not permissioned - email us at info@joinago.com"};
          res.state_count = count
          updateReservations(res);
          if (res_id === 'X') {
            setRes_id("-")
          }
          else
          {
            setRes_id("X")
          }
          updateIsLoading(false)
      }
    }

    if (reservations.state_count !== count) {
      fetchReservationsData()
    }
  },[count,homeTz,reservations,searchType,windowStartDate,windowDays,driver,resStartDate,resEndDate])

  const ReRoute = (resId) => {
    let urlTo = '../reservation/' + resId;
    navigate(urlTo);
  }


  const SelectReservation = async (resId) => {
    await setRes_id(resId);
    if(displaySideDock===false)
    {
      ReRoute(resId);
      //return <Navigate to={urlTo} />
      //updateShowReservationPage(true);
    } else {
      await updateDisplaySideDock(false);
      updateDisplaySideDock(true);
    }
  }

  const closeReservationDetails = async () => {
    setRes_id("");
    updateShowReservationPage(false);
  }


  return (  
      <Flex direction="column">
        {
        showReservationPage ? <Reservation paramResId={res_id} closeReservationDetails={closeReservationDetails} /> :
        <View style={{padding:"1rem"}}>
          <Heading level={1}>Reservations</Heading>
          <Text>These are the recent, current, and upcoming reservations booked on your vehicles through the mobile app</Text>
          <br/>
            {showAdvancedSearch ?
                <View>
                    <Flex direction="row" alignItems="flex-start">
                      <SelectField
                        label="Home Timezone"
                        descriptiveText="Select your home timezone..."
                        size="small"
                        options={reservations.tz_choices}
                        value={homeTz}
                        onChange={(e) => setHomeTz(e.target.value)}
                      ></SelectField>
                      <SelectField
                        label="Search Type"
                        descriptiveText="Select search type..."
                        size="small"
                        value={searchType}
                        onChange={(e) => setSearchType(e.target.value)}
                      >
                        <option value="existing" label="Existing Rsvns"></option>
                        <option value="availability" label="Test Availability"></option>
                        <option value="reservations" label="Request Rsvns"></option>  
                      </SelectField>
                      <SelectField
                        label="Driver"
                        descriptiveText="Select Driver here only for testing scheduling..."
                        size="small"
                        options={reservations.drivers}
                        value={driver}
                        onChange={(e) => setDriver(e.target.value)}
                      ></SelectField>
                    </Flex>
                    <Flex direction="row" alignItems="flex-start">
                      <Flex direction="column" alignItems="flex-start">
                        <Text>Window Start</Text>
                        <DatePicker
                              name="Window Start"
                              selected={windowStartDate}
                              onChange={(date) => setWindowStartDate(date)}
                              showTimeSelect
                              dateFormat="MMMM d, yyyy h:mm aa"
                              timeIntervals={60}
                            />
                        <SliderField label="Days in Window" value={windowDays} onChange={setWindowDays} min={7} max={90} step={1}/>
                      </Flex> 
                      <Flex direction="column" alignItems="flex-start">
                        <Text>Shift Request Start</Text>
                        <DatePicker
                              selected={resStartDate}
                              onChange={(date) => setResStartDate(date)}
                              showTimeSelect
                              dateFormat="MMMM d, yyyy h:mm aa"
                              timeIntervals={60}
                            />
                      </Flex>
                      <Flex direction="column" alignItems="flex-start">
                        <Text>Shift Request End</Text>
                        <DatePicker
                              selected={resEndDate}
                              onChange={(date) => setResEndDate(date)}
                              showTimeSelect
                              dateFormat="MMMM d, yyyy h:mm aa"
                              timeIntervals={60}
                            />
                      </Flex> 
                    </Flex>

                    <Button onClick={() => setCount(count + 1)}>
                                Submit
                    </Button>
                    <br /><br />
                    <Button onClick={() => updateShowAdvancedSearch(false)}>
                      Hide Advanced Search
                    </Button>
                  </View>
              :
                showAdvancedSearchButton ?
                  <Button onClick={() => updateShowAdvancedSearch(true)}>
                    Reveal Advanced Search
                  </Button>
                :
                  undefined
            }
            <View>
              <Button onClick={() => setCount(count + 1)}>
                  PRESS TO GET LATEST UPDATES
              </Button>
            </View>
            <Divider />
            <Text><i>Hint: Reservations highlighted red require your attention.</i></Text>
            <br/>
            {is_loading ? <Loadingmini /> : reservations.status !== "success" ? <Text>${reservations.status}</Text>:
                <Flex gap="2rem" wrap="wrap">
                  <Flex direction="column">
                    {
                    /*
                    <h5> Selected Res Id: {res_id} </h5>
                    res_id!=='x' && res_id!=='' ? 
                      <span><Button onClick={() => updateShowConfirmCloseReservation(true)}>
                                  Close Reservation
                      </Button><br/><p>{JSON.stringify(closeReservationResults)!=='{}' ? JSON.stringify(closeReservationResults) : undefined}</p><br/></span> : undefined
                    */}
                    <Timeline 
                      groups={reservations.cars_out}
                      items={reservations.rsvns_out}
                      keys={keys}
                      defaultTimeStart={new Date(reservations.display_start)}
                      defaultTimeEnd={new Date( reservations.display_end)}
                      sidebarWidth="250"
                      canMove={true}
                      canChangeGrop={false}
                      canResize={true}
                      showCursorLine
                      onItemSelect={(itemId, e, time) => {
                        SelectReservation(reservations.id_map.get(itemId.toString()))
                      }}   
                    >
                      <TimelineMarkers>
                        <TodayMarker interval={2000} />
                      </TimelineMarkers>
                      <TimelineHeaders style={{background: '#092F50', color:'#FFF', padding:'25px'}}>
                        <SidebarHeader>
                          {({ getRootProps }) => {
                            return <View {...getRootProps()}><Heading level={4} style={{color:'#FFF', paddingRight:'25px'}}>Vehicles</Heading></View>
                          }}
                        </SidebarHeader>
                      </TimelineHeaders>
                    </Timeline>           
                    <iframe id="reservations"   
                            title="Reservations"
                            width="700"
                            height="500"
                            srcdoc = {reservations.options} 
                    > 
                    </iframe>
                    {
                      /*<p> Updated on this use: {count} </p>*/
                    }
                  </Flex>
                  {
                    displaySideDock === false ? 
                      <Flex direction="column">
                        <Divider />
                          <Button onClick={() => updateDisplaySideDock(true)}>
                              ACTIVATE SIDE DOCK
                          </Button>
                        <Divider />
                      </Flex> 
                    :
                      res_id==="x" || res_id==="-"? <Text><i>Please select a reservation by clicking it...</i></Text> :
                      <Flex direction="column" style={{width:"30vw"}}>
                        <Divider />
                        <Heading level={2}>Reservation Details</Heading>
                        <Text><Button style={{border:'none', fontStyle: 'italic', marginLeft:'-15px'}} onClick={() => updateDisplaySideDock(false)}>To open reservations in full-screen mode, click here to hide the side dock.</Button></Text>
                        <Reservation paramResId={res_id} closeReservationDetails={closeReservationDetails} />
                        <Divider />
                      </Flex>
                  }
                </Flex>
            }
        </View>}
      </Flex>

  );
}