import React, { useState, useEffect } from 'react'
import { API, Auth } from 'aws-amplify';
import { NavLink} from "react-router-dom";
import "@aws-amplify/ui-react/styles.css";
import "react-datepicker/dist/react-datepicker.css";
import { Flex, Divider, Text, Heading, View, Button} from '@aws-amplify/ui-react';
//import Loading from '../components/loading';
import Loadingmini from '../components/loadingmini';
import { AgGridReact } from 'ag-grid-react'; // React Data Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the grid

export default function Alerts() {
    const [is_loading, updateIsLoading] = React.useState(false);
    const [count, setCount] = useState(0);
    const [res, updateRes] = useState({status:"Contact support if this message stays for more than 30 seconds",alerts:[]})

    const columns = [
        {
            field: 'region',
            filter: true,
            width: 120
        },
        {
            field: 'topic',
            filter: true,
            width: 400
        },
        {
            field: 'msg',
            filter: true,
            width: 400
        },
        {
            field: 'timestamp',
            filter: true
        },
        {
            headerName: 'Reservation',
            width: 180,
            field: 'res_id',
            filter: true,
            cellRenderer: params => {
                let val = params.value
                let link = '/reservation/' + String(val) 
                return( <NavLink to={link} index = {val}>{val}</NavLink>)
            }
        },
        {
            headerName: 'Driver Link',
            width: 75,
            field: 'driver_id',
            filter: true,
            cellRenderer: params => {
                let val = params.value
                let link = '/user/' + String(val) 
                return( <NavLink to={link} index = {val}>{val}</NavLink>)
            }
        },
        {
            headerName: 'Driver Name',
            field: 'driver_name',
            filter: true
        },
        {
            headerName: 'Vehicle Link',
            width: 75,
            field: 'car_id',
            filter: true,
            cellRenderer: params => {
                let val = params.value
                let link = '/vehicle/' + String(val) 
                return( <NavLink to={link} index = {val}>{val}</NavLink>)
            }
        },
        {
            headerName: 'Vehicle Name',
            field: 'car_name',
            filter: true
        },
        {
            field: 'fleet_code',
            filter: true
        },
    ];


    async function fetchPageData() {
        updateIsLoading(true)
        let session = await Auth.currentSession()      
        let accessToken = await session.getAccessToken()
        let jwt = await accessToken.getJwtToken()
        const myInit = {queryStringParameters:{ 
            "back_end": process.env.REACT_APP_BACKEND,
            "jwt": jwt,
            "api_endpoint":"alerts"
        }};
        try {
            const res = await API.get('adminWebTools','/ago',myInit)
            updateRes(res)
            updateIsLoading(false)
        }
        catch (e) {
            console.log('error: '+toString(e));
            updateIsLoading(false)
        }
    }

    useEffect(() => {
        fetchPageData()
    },[count])
    
//,Auth.currentSession()


  return (
      <Flex
        direction="column"
      >
        <View style={{padding:"1rem"}}>
            <Flex 
                direction='column'
            >
                <Heading level={1}>Critical Alerts</Heading>
                <Text style={{width:'100%'}}>These are time-sensitive, critical alerts that require your attention.</Text>
                <View>
                    <Button onClick={() => setCount(count + 1)}>
                        PRESS TO GET LATEST UPDATES
                    </Button>
                </View>
                <Flex direction="column" style={{width:"80vw",maxWidth:"2200px"}}>
                    <Divider />
                    <View 
                        className="ag-theme-quartz" // applying the grid theme
                        style={{ height: 500 }} // the grid will fill the size of the parent container
                    >
                        {
                            is_loading ? <Loadingmini /> : 
                            !res ? <Text>You have no unread alerts</Text> :
                            !res.alerts ? <Text>You have no unread alerts</Text> :
                            res.alerts.length > 0 === false ? <Text>You have no unread alerts</Text> :
                                <AgGridReact
                                    rowData={res.alerts}
                                    columnDefs={columns}
                                    rowSelection={'multiple'}
                                    paginationPageSize={500}
                                    paginationPageSizeSelector={[10, 25, 50]}
                                    enableCellTextSelection={true}
                                />
                        }
                    </View>
                </Flex>
            </Flex>
        </View>
      </Flex>
  );
}
