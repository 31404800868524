//import { useParams } from "react-router-dom";
import React from 'react'
import { Heading, Text, View, Flex, Divider } from '@aws-amplify/ui-react';
import "@aws-amplify/ui-react/styles.css";
import "react-datepicker/dist/react-datepicker.css";
import Profile from './profile';

export default function AccountInfoAlt({isHomepage}) {
 
  return (
    <Flex   //  https://ui.docs.amplify.aws/react/components/flex#demo
        direction='column'      //direction={{ base: 'column', large: 'row' }}
        //justifyContent='normal'           justifyContent={{ base: 'flex-start', large: 'flex-start' }}        //justifyContent="center"
        //alignItems='stretch'              alignItems={{ base: 'stretch', large: 'flex-start' }}       //alignItems="center" // alignItems="flex-end" (right)
        //alignContent='normal'             alignContent="flex-start"       //alignContent="center"
        wrap="wrap" //nowrap
        gap="1rem"
    >
      <View style={{padding:"1rem"}}>
          <Flex 
              direction='column'
          >
              <Heading level={1}>Account Info</Heading>
              <Text style={{width:'100%'}}>View and update your account information below.</Text>
              <Profile />
              <View style={{padding:"1rem"}}>
                <Divider />
                <Heading level={2}>Phone Number</Heading>
                <Text>Your phone number can be viewed in the left drawer under 'Account' and cannot be changed at this time.</Text>
              </View>
          </Flex>
      </View>
    </Flex>
  );
}