import React, { useState, useEffect } from 'react'
import { API, Auth } from 'aws-amplify';
import { NavLink} from "react-router-dom";
import "@aws-amplify/ui-react/styles.css";
import "react-datepicker/dist/react-datepicker.css";
import { Flex, Divider, Text, Heading, View, Button, TextField} from '@aws-amplify/ui-react';
import { AgGridReact } from 'ag-grid-react'; // React Data Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the grid
import Loadingmini from '../components/loadingmini';

export default function Usercredits({userId}) {
    const [is_loading, updateIsLoading] = React.useState(false);
    const [display_form, updateDisplayForm] = React.useState(false);
    const [count, setCount] = useState(0);
    const [credits, setCredits] = useState(0);
    const [res_id, setResId] = useState("");
    const [notes, setNotes] = useState("");
    const [credits_to_grant, setCreditsToGrant] = useState(0);
    const [res, updateRes] = useState({status:"Contact support if this message stays for more than 30 seconds",rows:[]})
    const columns = [
        {
            headerName: 'Datetime',
            field: 'created',
            filter: true
        },
        {
            headerName: 'Entry Type',
            field: 'entry_type',
            filter: true
        },
        {
            headerName: 'Amount',
            field: 'amount',
            filter: true
        },
        {
            headerName: 'Shift',
            field: 'shift',
            filter: true
        },
        {
            headerName: 'Notes',
            field: 'notes',
            filter: true
        },
        {
            headerName: 'UserID',
            field: 'user_id',
            filter: true
        },
        {
            headerName: 'Name',
            field: 'name_full',
            filter: true
        },
        {
            headerName: 'FleetID',
            field: 'fleet_id',
            filter: true
        }
  ];
  

  async function fetchPageData() {
    if(userId > 0)
    {
        updateIsLoading(true)
        let session = await Auth.currentSession()      
        let accessToken = session.getAccessToken()
        let jwt = accessToken.getJwtToken()
        const myInit = {queryStringParameters:{ 
            "back_end": process.env.REACT_APP_BACKEND,
            "jwt": jwt,
            "api_endpoint":"drivercredits",
            "driver_id":userId
        }};
        try {
            const res = await API.get('adminWebTools','/ago',myInit)
            var new_credits = 0
            res.rows.forEach(item => {
                try {
                    var credits_to_add = parseInt(item['amount'])
                    console.log('current credits:'+new_credits)
                    console.log('credits_to_add:'+credits_to_add)
                    new_credits = new_credits + credits_to_add
                    console.log('new credits:'+new_credits)
                }
                catch (e) {
                    console.log('drivercredits error sum credits: '+toString(e));
                    updateIsLoading(false)
                }
                setCredits(new_credits)
            })
            console.log('drivercredits user ' + userId + ' : ' + JSON.stringify(res))
            updateRes(res)
            updateIsLoading(false)
        }
        catch (e) {
            console.log('drivercredits error: '+toString(e));
            updateIsLoading(false)
        }
        updateIsLoading(false)
    }
  }

  async function grantCredits() {
    if(userId > 0)
    {
        updateIsLoading(true)
        let session = await Auth.currentSession()      
        let accessToken = session.getAccessToken()
        let jwt = accessToken.getJwtToken()
        var t_credits_to_grant = parseInt(credits_to_grant)
        const myInit = {queryStringParameters:{ 
            "back_end": process.env.REACT_APP_BACKEND,
            "jwt": jwt,
            "api_endpoint":"drivercreditsgrantcredits",
            "driver_id":userId,
            "credits_to_grant":t_credits_to_grant,
            "res_id":res_id,
            "notes":notes
        }};
        try {
            const res = await API.get('adminWebTools','/ago',myInit)
            console.log('drivercreditsgrantcredits user ' + userId + ' : ' + JSON.stringify(res))
            setCount(count + 1)
        }
        catch (e) {
            console.log('drivercredits error: '+toString(e));
            updateIsLoading(false)
        }
        updateIsLoading(false)
    }
  }

  useEffect(() => {
    fetchPageData()
    },[count, userId])



  return (
      <Flex
        direction="column"
      >
        <View style={{padding:"1rem"}}>
            <Divider />
            {
                is_loading ? <Loadingmini /> : 
                <Flex 
                    direction='column'
                >
                    <Heading level={2}>Driver Credits</Heading>
                    <Text style={{width:'100%'}}>This is the history of credits issued to and removed from the driver.</Text>
                    <View>
                        <Button onClick={() => setCount(count + 1)}>
                            PRESS TO GET LATEST UPDATES
                        </Button>
                    </View>
                    <Divider />
                    <Text><i>Hint: To search, long-click on mobile or click top-right on pc.</i></Text>
                    <Text>Total credits:  {credits}</Text>
                    <View style={{width:"100%"}}>
                        <View 
                            className="ag-theme-quartz" // applying the grid theme
                            style={{ height: 500 }} // the grid will fill the size of the parent container
                        >
                                <AgGridReact
                                    rowData={res.rows}
                                    columnDefs={columns}
                                    rowSelection={'multiple'}
                                    paginationPageSize={500}
                                    paginationPageSizeSelector={[10, 25, 50]}
                                    enableCellTextSelection={true}
                                />
                        </View>
                    </View>
                    
                        { display_form === false ? 
                            <Flex direction="column" gap="1rem" >
                                <Button onClick={() => updateDisplayForm(true)}>GRANT OR REMOVE CREDITS</Button>
                            </Flex>
                        : 
                            <Flex direction="column" gap="1rem" >
                                <Heading level={2}>Driver Credits</Heading>
                                <Text>Grant this user credits toward reservations. Enter a negative number to remove credits.</Text>
                                <TextField 
                                    placeholder='credits to grant or remove'
                                    onInput={(e) => setCreditsToGrant(e.target.value)} />
                                
                                <TextField
                                    placeholder='notes on why you are granting credits'
                                    onInput={(e) => setNotes(e.target.value)}
                                ></TextField>
                                <TextField
                                    placeholder='reservation ID (if applicable)'
                                    onInput={(e) => setResId(e.target.value)}
                                ></TextField>
                                <Button onClick={() => grantCredits()}>SUBMIT</Button>
                                <br/>
                                <Button onClick={() => updateDisplayForm(false)}>HIDE FORM</Button>
                            </Flex>
                        }
                    <Divider />
                </Flex>
            }
        </View>
      </Flex>
  );
}
