import React, { useState } from 'react'
import "@aws-amplify/ui-react/styles.css";
import "react-datepicker/dist/react-datepicker.css";
import Loadingmini from '../components/loadingmini';
import { Flex, Divider, View, Text, Heading, Button} from '@aws-amplify/ui-react';


export default function Userinsurancedata({user}) {
  const [is_loading, updateIsLoading] = React.useState(false);

  function formatDate(UNIX_timestamp){
    var a = new Date(UNIX_timestamp * 1000);
    var months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
    var year = a.getFullYear();
    var month = months[a.getMonth()];
    var date = a.getDate();
    var hour = a.getHours();
    //var min = a.getMinutes();
    //var sec = a.getSeconds();
    var days = ['Sunday','Monday','Tuesday','Wednesday','Thursday','Friday','Saturday']
    var day = days[a.getDay()];
    if(hour > 12)
    {
      var thour = hour - 12
      var pmam = ' PM';
    } else {
      if(hour < 12)
      {
        if(hour < 1)
        {
          var thour = '12';
          var pmam = ' AM';
        } else {
          var thour = hour;
          var pmam = ' AM';
        }
      } else {
        var thour = '12';
        var pmam = ' PM';
      }
    }
    var time = day + ', ' + month + ' ' + date + ', ' + year + ' - ' + thour + pmam;
    return time;
  }

  return (
    is_loading ? <Loadingmini /> : 
      <Flex
        direction="column"
      >
        <View style={{padding:"1rem"}}>
          <Divider />
          {
            user ?
              user.resp ?
                user.resp[0] ?  
                  typeof(user.resp[0]["driver_insurance_data"]) === 'undefined' ?  
                  <Flex direction="column">
                      <Heading level={2}>No Insurance Data</Heading>
                      <Text>If this driver is new, it may take up to a week for new data to populate.</Text>
                      <Text>If you aren't getting your insurance through <a href='https://agorisk.com/'>Ago</a>, you could be saving a bundle.</Text>
                  </Flex>
                  :
                  user.resp[0]["driver_insurance_data"] === false || user.resp[0]["driver_insurance_data"] === null ? 
                  <Flex direction="column">
                      <Heading level={2}>No Insurance Data</Heading>
                      <Text>If this driver is new, it may take up to a week for new data to populate.</Text>
                      <Text>If you aren't getting your insurance through <a href='https://agorisk.com/'>Ago</a>, you could be saving a bundle.</Text>
                  </Flex>
                  :
                  user.resp[0]["driver_insurance_data"]["TotMls"] === false ? //just a general check... since the line above doesn't seem to work
                  <Flex direction="column">
                      <Heading level={2}>No Insurance Data</Heading>
                      <Text>If this driver is new, it may take up to a week for new data to populate.</Text>
                      <Text>If you aren't getting your insurance through <a href='https://agorisk.com/'>Ago</a>, you could be saving a bundle.</Text>
                  </Flex>
                  :
                  <Flex direction="column">
                    <Heading level={2}>Insurance Data</Heading>
                    <Flex wrap="wrap" gap="2rem" justifyContent="center">
                      <Flex direction="column">
                        <Divider />
                        <table><tbody>
                          {user.resp[0]["period_start"] ? 
                          <tr>
                            <th style={{paddingRight:'10px'}}>Period Start:</th>
                            <td>{formatDate(user.resp[0]["period_start"])}</td>
                          </tr>
                          : undefined}
                          {user.resp[0]["period_end"] ? 
                          <tr>
                            <th style={{paddingRight:'10px'}}>Period End:</th>
                            <td>{formatDate(user.resp[0]["period_end"])}</td>
                          </tr>
                          : undefined}
                          {user.resp[0]['driver_insurance_data']["TotMls"] ? 
                          <tr>
                            <th style={{paddingRight:'10px'}}>Total Miles:</th>
                            <td>{Math.round(user.resp[0]['driver_insurance_data']["TotMls"],0)}</td>
                          </tr>
                          : 
                          <tr>
                            <th style={{paddingRight:'10px'}}>Total Miles:</th>
                            <td>0</td>
                          </tr>}
                          {user.resp[0]['driver_insurance_data']["PosSpdHrsTot"] ? 
                          <tr>
                            <th style={{paddingRight:'10px'}}>Driving Hours:</th>
                            <td>{Math.round(user.resp[0]['driver_insurance_data']["PosSpdHrsTot"],0)}</td>
                          </tr>
                          : 
                          <tr>
                            <th style={{paddingRight:'10px'}}>Driving Hours:</th>
                            <td>0</td>
                          </tr>}
                          {user.resp[0]['driver_insurance_data']["Liab$"] ? 
                          <tr>
                            <th style={{paddingRight:'10px'}}>Liability $:</th>
                            <td>${Math.round(user.resp[0]['driver_insurance_data']["Liab$"],2)}</td>
                          </tr>
                          : 
                          <tr>
                            <th style={{paddingRight:'10px'}}>Liability $:</th>
                            <td>$0</td>
                          </tr>}
                          {user.resp[0]['driver_insurance_data']["PhysDam$"] ? 
                          <tr>
                            <th style={{paddingRight:'10px'}}>Physical Damage $:</th>
                            <td>${Math.round(user.resp[0]['driver_insurance_data']["PhysDam$"],2)}</td>
                          </tr>
                          : 
                          <tr>
                            <th style={{paddingRight:'10px'}}>Physical Damage $:</th>
                            <td>$0</td>
                          </tr>}
                        </tbody></table>
                        <br/>
                      </Flex>
                    </Flex>
                  </Flex>
                : 
                  <Flex direction="column">
                    <Text>Error loading user data</Text>
                    <Text>No data found for user</Text>
                  </Flex>
              : 
                <Flex direction="column">
                  <Text>Error loading user data</Text>
                  <Text>No user data</Text>
                </Flex>
            : 
              <Flex direction="column">
                <Text>Error loading user data</Text>
                <Text>User not found</Text>
              </Flex>
          }
        </View>
      </Flex>
  );
}
